<template>
    <b-dropdown
        v-if="isAdmin && isMyProject(form.formMeta)"
        size="sm"
        variant="link"
        class="plain-dropdown"
        toggle-class="text-decoration-none"
        no-caret
    >
        <template v-slot:button-content>
            <i class="fa fa-cog"></i>
        </template>

        <li>
            <platon-link class="dropdown-item" :link="`/forms/forms/${form.formMeta.id}?_target=modal`">
                <i class="fa fa-cog mr-2" /> {{ $l("platon.form_setup", "Ойнани созлаш") }}
            </platon-link>
        </li>

        <li>
            <platon-link
                class="dropdown-item"
                :link="`/forms/form_elements?form_name=${form.formMeta.name}&_target=modal`"
            >
                <i class="fa fa-plus-circle mr-2" /> {{ $l("platon.form_add_element", "Янги элемент қўшиш") }}
            </platon-link>
        </li>

        <li>
            <platon-link
                class="dropdown-item"
                :link="`/tables/form_elements?form_name=${form.formMeta.name}&_target=blank`"
            >
                <i class="fa fa-bars mr-2" /> {{ $l("platon.form_element_list", "Элементлар рўйхати") }}
            </platon-link>
        </li>
    </b-dropdown>
</template>
<script>
import Btn from "@Platon/components/extended/Btn.vue"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"

export default {
    name: "PlatonFormAdminModalActions",

    components: { Btn, PlatonLink },

    mixins: [PermissionMixin],

    props: {
        form: {}
    }
}
</script>
